
import { defineComponent, ref, unref, toRefs, watch, onMounted, computed } from 'vue';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader-v2.vue';
import { AnnouncementType, CreateAnnouncementData, Photo, TargetUserType } from '@/interfaces';
import Tinymce from '@/components/tinymce/Index.vue';
import dayjs from 'dayjs';
import { CREATED_SUCCESSFULLY_TEXT, ResponseError, UPDATED_SUCCESSFULLY_TEXT } from '@/services/api';
import { useUpdateAnnouncement, useAnnouncement, useCreateAnnouncement } from '@/composables/api';
import { useRoute, useRouter } from 'vue-router';
import { normalizeRequestData } from '@/utils/normalization';
import UploadExcel from '@/components/up-excel/Index.vue';
import { DEFAULT_LANG_CODE } from '../constants';
import { useLang } from '../composables';

type ExcelResult = { 會員編號: string }

const DEFAULT_FORM_VALUES: CreateAnnouncementData & {
  notificationPhoto?: Photo
} = {
  type: AnnouncementType.SYSTEM,
  title: '',
  notificationPhotoId: null,
  content: '',
  mobileContent: '',
  targetUsers: TargetUserType.ALL,
  startedAt: '',
  endedAt: '',
  isEnabled: false,
  language: DEFAULT_LANG_CODE
};

const REQUIRED_RULE = {
  required: true,
  message: '此為必填欄位'
};

const RULES = {
  type: [REQUIRED_RULE],
  title: [REQUIRED_RULE],
  notificationPhoto: [
    {
      message: '請選擇通知圖片',
      required: true,
      trigger: 'blur'
    }
  ],
  content: [REQUIRED_RULE],
  mobileContent: [REQUIRED_RULE],
  targetUsers: [REQUIRED_RULE],
  startedAt: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ],
  language: [REQUIRED_RULE]
};

const TARGET_USERS = [
  { label: '所有會員', value: 'all' },
  { label: '新會員', value: 'new' },
  { label: '指定會員', value: 'specific' }
];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader,
    Tinymce,
    UploadExcel
  },
  setup(props) {
    const router = useRouter();
    const { isEdit } = toRefs(props);
    const announcementId = useRoute().params.id as string;

    const formRef = ref<typeof ElForm>();

    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const isFormLoading = ref(true);
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);

    const { isLoading: isCreating, mutate: create } = useCreateAnnouncement();
    const { isLoading: isUpdating, mutate: update } = useUpdateAnnouncement();

    const { data, dataUpdatedAt } = useAnnouncement({ announcementId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const { langs } = useLang();

    const { formErrors, bindFormItemError } = useFormErrors();
    const defaultEndTime = ref(new Date(2000, 2, 1, 23, 59, 59));

    const handleSuccess = ({ results }) => {
      const memberNumbers = [];
      results.forEach((result: ExcelResult) => {
        memberNumbers.push(result.會員編號);
      });

      formValues.value.specificMemberNos = memberNumbers;
    };

    const handleImageCrop = (image: UploadImageEvent) => {
      formValues.value.notificationPhoto = image;
      formValues.value.notificationPhotoId = image.id;
    };

    const imageSource = computed(() => formValues.value.notificationPhoto?.path);

    const disabledStartDate = (datesOnCalendar: any) => {
      const today = dayjs().startOf('day').toDate();
      return datesOnCalendar.getTime() < today;
    };

    const disabledEndDate = (datesOnCalendar: any): Boolean => {
      const startDateTimestamp = dayjs(formValues.value.startedAt).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;

      return beforeStartDate;
    };

    const isAnnouncementExpired = computed(() => {
      const now = new Date().valueOf();
      const endDateTimestamp = dayjs(data.value?.data?.endedAt).valueOf();
      const afterEndDate = now > endDateTimestamp;

      return afterEndDate;
    });

    const isStartDateChangeable = computed(() => {
      const now = new Date().valueOf();
      const startDateTimestamp = dayjs(data.value?.data?.startedAt).valueOf();
      const beforeStartDate = now > startDateTimestamp;

      return beforeStartDate;
    });

    const isTop = computed(() => data.value?.data?.isTop);

    const handleContentChange = (content: string) => {
      formValues.value.content = content;
    };

    const handleMobileContentChange = (content: string) => {
      formValues.value.mobileContent = content;
    };

    const handleStartedAtChange = (e: Date) => {
      if (!dayjs(e).isBefore(dayjs().add(10, 's')) || !dayjs(e).isAfter(dayjs().subtract(10, 's'))) return;
      formValues.value.startedAt = dayjs(e).add(1, 'm').toISOString();
    };

    const removeSameDateTime = (formValues: typeof DEFAULT_FORM_VALUES) => {
      const { startedAt, endedAt, ...resFormValue } = formValues;

      return {
        ...resFormValue,
        ...(startedAt !== data.value.data?.startedAt && { startedAt }),
        ...(endedAt !== data.value.data?.endedAt && { endedAt })
      };
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (!Array.isArray(formValues.value.specificMemberNos) && (formValues.value.specificMemberNos !== undefined)) {
          const specificMemberNos = (formValues.value.specificMemberNos as String).split(',');
          formValues.value.specificMemberNos = specificMemberNos;
        }

        if (valid) {
          if (isEdit.value) {
            update(
              {
                announcementId,
                data: normalizeRequestData(removeSameDateTime(formValues.value))
              },
              {
                onSuccess() {
                  ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                  router.go(-1);
                },
                onError(error: ResponseError) {
                  ElMessage.error(error.response?.data.message);
                  formErrors.value = error.response?.data.message;
                }
              }
            );
            return;
          }

          create(
            { data: normalizeRequestData(formValues.value) },
            {
              onSuccess() {
                ElMessage.success(CREATED_SUCCESSFULLY_TEXT);
                formValues.value = DEFAULT_FORM_VALUES;

                router.go(-1);
              },
              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.message;
              }
            }
          );
        }
      });
    };

    onMounted(() => {
      if (!isEdit.value) {
        isFormLoading.value = false;
      }
    });

    watch(dataUpdatedAt, () => {
      formValues.value = { ...data?.value?.data };

      isFormLoading.value = false;
    });

    return {
      RULES,
      TARGET_USERS,
      langs,
      formRef,
      formValues,
      isCreating,
      isUpdating,
      formErrors,
      defaultTime,
      imageSource,
      isFormLoading,
      defaultEndTime,
      TargetUserType,
      AnnouncementType,
      isAnnouncementExpired,
      isTop,
      submitForm,
      handleImageCrop,
      disabledEndDate,
      disabledStartDate,
      bindFormItemError,
      handleSuccess,
      handleContentChange,
      handleStartedAtChange,
      handleMobileContentChange,
      isStartDateChangeable
    };
  }
});
